/* Import React Toastify CSS */
@import 'react-toastify/dist/ReactToastify.css';

/* Import your global styles */
@import '../src/assets/styles/index.css';

/* Import Tailwind's base styles */
@tailwind base;

/* Import Tailwind's component styles */
@tailwind components;

/* Import Tailwind's utility styles */
@tailwind utilities;