@import url("https://use.typekit.net/kvg8tpy.css");
@import url("react-datepicker/dist/react-datepicker.css");

@font-face {
    font-family: 'MyriadPro-Regular';
    src: url('../fonts/MyriadPro-Regular.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'RedSoxNation';
    src: url('../fonts/redsoxnationnormal.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Maleo';
    src: url('../fonts/Maleo-Variable.ttf') format('truetype');
  }

  /* Custom Toastify Progress Bar */
.Toastify__progress-bar {
  background: linear-gradient(
    90deg,
    #EE3337, /* redz-red */
    #F0E2CB, /* redz-white */
    #000     /* black */
  ) !important;
}